package tz.co.pesalock.components.navigation

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint


@Composable
fun Navigation(
    onDrawerClicked: () -> Unit,
    breakpoint: Breakpoint,
    modifier: Modifier
) {
    Row(
        modifier = modifier,
        horizontalArrangement = Arrangement.SpaceBetween,
        verticalAlignment = Alignment.CenterVertically
    ){
        LogoAndTitle(breakpoint)
        MenuList(
            onDrawerClicked = onDrawerClicked,
            breakpoint = breakpoint)
    }
}