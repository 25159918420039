package tz.co.pesalock.components.tools

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Form
import tz.co.pesalock.components.atoms.ActionButton
import tz.co.pesalock.components.atoms.LabeledTextField


@Composable
fun MailForm(
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier
        .padding(
            leftRight = if (breakpoint > Breakpoint.MD) 104.px else 0.px,
            topBottom = if (breakpoint > Breakpoint.MD) 20.px else 5.px
        )
        .height(auto)
        .background(
            color = Color("#2F4858"),
        )
    ,
    onMessageSend: (SyntheticMouseEvent) -> Unit,
) = Column(
    modifier = modifier,
    horizontalAlignment = Alignment.CenterHorizontally,
    verticalArrangement = Arrangement.Center
) {
    val message by remember { mutableStateOf("") }
    Form(
        action = "https://formspree.io/f/xwpkwngv",
        attrs = Modifier.margin(0.px).fillMaxWidth().height(auto).attrsModifier { attr("method", "POST") }.toAttrs()
    ) {
    LabeledTextField(
        modifier = Modifier.fillMaxWidth().height(auto).margin(topBottom = 8.px),
        label = "First Name",
        labelColor = Color("#2F4858"),
        inputId = "firstName",
    )
    LabeledTextField(
        modifier = Modifier.fillMaxWidth().height(auto).margin(topBottom = 8.px),
        label = "Last Name",
        labelColor = Color("#2F4858"),
        inputId = "lastName",
    )
    LabeledTextField(
        modifier = Modifier.fillMaxWidth().height(auto).margin(topBottom = 8.px),
        label = "Your Email",
        labelColor = Color("#2F4858"),
        inputId = "email",
        acceptedInput = InputType.Email
    )
    LabeledTextField(
        modifier = Modifier.fillMaxWidth().height(auto).margin(topBottom = 8.px),
        label = "Message",
        labelColor =Color("#2F4858"),
        inputId = "message",
        textAreaValue = message,
        numberOfLines = 100
    )

    ActionButton(
        modifier = Modifier
            .fillMaxWidth()
            .height(50.px)
            .margin(topBottom = 8.px)
            .borderRadius(8.px)
            .border(1.px, color = Color("#73BA7F"), style = LineStyle.Solid)
            .background(color = Color("#73BA7F")),
        text = "SEND MESSAGE",
        onClick = onMessageSend,
        containerColor = "#73BA7F",
        textColor = "#FFF"
    )
    }
}