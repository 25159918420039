package tz.co.pesalock.components.howtouse

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.tools.Underline
import tz.co.pesalock.utils.Res


@Composable
fun HowToUseApp(
    breakpoint: Breakpoint,
    modifier: Modifier
) {
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {
        H1(
            attrs = Modifier
                .margin(0.px)
                .fillMaxWidth(100.percent)
                .height(auto)
                .fontSize(if (breakpoint > Breakpoint.MD) 30.px else 20.px)
                .fontWeight(700)
                .fontFamily(Res.Font.poppins_black)
                .padding(topBottom = 20.px, leftRight = 12.px)
                .color(color = Color("#2F4858"))
                .lineHeight(15.px)
                .textAlign(textAlign = TextAlign.Center)
                .toAttrs()
        ) {
            Text(
                value = "HOW TO USE PESALOCK"
            )
        }

        H2(
            attrs = Modifier
                .fillMaxWidth(100.percent)
                .height(auto)
                .fontSize(if (breakpoint > Breakpoint.MD) 24.px else 18.px)
                .fontWeight(700)
                .fontFamily(Res.Font.poppins_bold)
                .margin(topBottom = if (breakpoint > Breakpoint.MD) 20.px else 10.px, leftRight = 12.px)
                .color(color = Color("#2F4858"))
                .lineHeight(15.px)
                .textAlign(textAlign = TextAlign.Center)
                .toAttrs()
        ) {
            Text(
                value = "RECEIVE PAYMENT ON UPCOUNTRY DELIVERIES"
            )
        }

        Underline()

        Column(
            modifier = Modifier
                .padding(16.px)
                .fillMaxWidth(100.percent)
                .height(auto)
                .padding(left = 12.px, right = 12.px),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            SimpleGrid(
                modifier = Modifier.fillMaxWidth(),
                numColumns = numColumns(base = 1, sm = 1, md = 2, lg = 2)
            ) {
                HowToContent(breakpoint)
                HowToContent(
                    breakpoint,
                    itemNumber = 2,
                    itemTitle = "Create An Agreement",
                    itemDescription = "Describe the terms of the agreement, include pictures if possible, add prices and choose a region and pick up point, ship the product to customer",
                    iconLink = Res.Drawable.CREATE_AGREEMENT
                )
                HowToContent(
                    breakpoint,
                    itemNumber = 3,
                    itemTitle = "Pick Up And Payment",
                    itemDescription = "On arrival of goods a customer will go to the nearest agent to complete the pickup and pay for the delivered goods, the agent will initiate the payment process through a push to pay notification",
                    iconLink = Res.Drawable.DELIVERY
                )
                HowToContent(
                    breakpoint,
                    itemNumber = 4,
                    itemTitle = "Pesalock Pays Seller/Business Quickly",
                    itemDescription = "After the goods have been delivered and paid for Pesalock routes the payment to the business/seller account as quickly as possible, payment is done on the spot without delays allowing the business to ship more to other customers",
                    iconLink = Res.Drawable.BUSINESS_PAYMENT
                )

            }
        }


        //HOW TO USE PESALOCK TO LOCK FUNDS

        Column(
            modifier = Modifier
                .margin(top = 32.px)
                .fillMaxWidth(100.percent)
                .height(auto)
                .padding(left = 12.px, right = 12.px),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            H2(
                attrs = Modifier
                    .fillMaxWidth(100.percent)
                    .height(auto)
                    .fontSize(if (breakpoint > Breakpoint.MD) 24.px else 18.px)
                    .fontWeight(700)
                    .fontFamily(Res.Font.poppins_bold)
                    .margin(topBottom = if (breakpoint > Breakpoint.MD) 20.px else 10.px, leftRight = 12.px)
                    .color(color = Color("#2F4858"))
                    .lineHeight(15.px)
                    .textAlign(textAlign = TextAlign.Center)
                    .toAttrs()
            ) {
                Text(
                    value = "LOCK FUNDS TILL AGREEMENT IS FULFILLED"
                )
            }

            Underline()

            Column(
                modifier = Modifier
                    .fillMaxWidth()
                    .height(auto)
                    .padding(leftRight = 12.px),
                verticalArrangement = Arrangement.SpaceEvenly,
                horizontalAlignment = Alignment.Start,
            ) {
                SimpleGrid(
                    modifier = Modifier.fillMaxWidth(),
                    numColumns = numColumns(base = 1, sm = 1, md = 2, lg = 2)
                ) {
                    HowToContent(
                        breakpoint,
                        itemDescription = "Decide on what you want to trade, whether its buying and provisional of services or goods that you would like to ensure your commitment into the transaction,use Pesalock to seal this agreement by going to the second step\n",
                    )
                    HowToContent(
                        breakpoint,
                        itemNumber = 2,
                        itemTitle = "Create An Agreement",
                        itemDescription = "Describe the terms of the agreement, include pictures if possible, add prices and choose a region and pick up point/handover location, ship the product to customer \n\n",
                        iconLink = Res.Drawable.CREATE_AGREEMENT
                    )
                    HowToContent(
                        breakpoint,
                        itemNumber = 3,
                        itemTitle = "Confirm Payment To Seller/Service Provider",
                        itemDescription = "After the goods/service haas been delivered and paid for Pesalock routes the payment to the business/\nseller/service provider account as quickly as possible, payment is done on the spot without delays\n allowing the business to ship more to other customers\n",
                        iconLink = Res.Drawable.BUSINESS_PAYMENT
                    )


                }
            }
        }
    }
}