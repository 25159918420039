package tz.co.pesalock.components.atoms

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import kotlinx.coroutines.CoroutineScope
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.px
import tz.co.pesalock.utils.Res


@Composable
fun MenuItem(
    locationId: String,
    itemName: String,
    modifier: Modifier = Modifier
        .fontSize(16.px)
        .textDecorationLine(TextDecorationLine.None)
        .color(color = Color("#2F4858"))
        .fontWeight(value = 800)
        .fontFamily(Res.Font.poppins_bold)
) {
    Link(
        path = locationId,
        text = itemName,
        modifier = modifier,
        openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE
    )
}