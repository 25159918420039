package tz.co.pesalock.components.tools

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import tz.co.pesalock.components.HeroSection
import tz.co.pesalock.components.navigation.Navigation
import tz.co.pesalock.utils.Res


@Composable
fun Home(
    onDrawerClicked: () -> Unit,
    breakpoint: Breakpoint,
    modifier: Modifier
) {
    Box(
        modifier = modifier,
        contentAlignment = Alignment.TopStart
    ) {
        Image(
            modifier = Modifier.align(Alignment.TopCenter).fillMaxWidth(100.percent).objectFit(objectFit = ObjectFit.Fill),
            src = Res.Drawable.HERO_BACKGROUND,
            description = "hero background image",
        )
        Column(
            modifier = Modifier
                .thenIf(breakpoint <= Breakpoint.MD, Modifier.fillMaxWidth(100.percent))
                .thenIf(
                    breakpoint > Breakpoint.MD,
                    Modifier.fillMaxWidth(90.percent)
                )
                .height(auto)
                .align(Alignment.TopCenter),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top,

            ) {
            Navigation(
                onDrawerClicked = onDrawerClicked,
                breakpoint = breakpoint,
                modifier = Modifier
                    .fillMaxWidth(100.percent)
                    .fillMaxHeight(
                        if (breakpoint >= Breakpoint.SM && breakpoint <= Breakpoint.MD) 20.percent else
                            10.percent
                    )
                    .padding(top = 2.percent, leftRight = 5.percent)

            )
             HeroSection(breakpoint)
        }
    }
}