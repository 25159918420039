package tz.co.pesalock.utils

import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.icons.fa.FaLocationDot
import com.varabyte.kobweb.silk.components.icons.fa.FaPhone
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.px

val menuList = listOf(
    MenuList(
        locationId = "#home",
        itemName = "Home",
    ),
    MenuList(
        locationId = "#how-it-works",
        itemName = "How it works",
    ),
    MenuList(
        locationId = "#services",
        itemName = "Services",
    ),
    MenuList(
        locationId = "#about",
        itemName = "About",
    ),
    MenuList(
        locationId = "#contact",
        itemName = "Contact",
    )
    )

val addressChannels = listOf<Address>(
    Address(
        icon = {
            FaLocationDot(
                modifier =  Modifier
                    .margin(top = 16.px)
                    .width(24.px)
                    .height(24.px)
                    .color(Color("#168C2A")),
                size = IconSize.LG
            )
        },
        channel = "Head Office",
        details = "Victoria House 9th Floor,\nRegent Estate, Mikocheni"
    ),
    Address(
        icon = {
            FaPhone(
                Modifier
                    .margin(topBottom = 4.px)
                    .width(24.px)
                    .height(24.px)
                    .color(Color("#168C2A")),
                size = IconSize.X1
            )
        },
        channel = "Customer Query",
        details = "+255686448421"
    )
)

val socials = listOf("Instagram","LinkedIn","Facebook","Twitter")
val company = listOf("FAQ","Support","Term of Service")
val product = listOf("Android","iOS","Web")
val about = listOf("Pesalock","News")
