package tz.co.pesalock.components.tools

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.textDecorationLine
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.components.herosection.CoRFlex
import tz.co.pesalock.utils.Res


@Composable
fun CallToAction(breakpoint: Breakpoint) {
    Box(
        modifier = Modifier
            .margin(topBottom = 100.px)
            .borderRadius(if (breakpoint > Breakpoint.MD) 0.px else 16.px)
            .fillMaxWidth(90.percent)
            .height(auto)
            .background(color = Color("#233642"))
    ) {
        Image(
            modifier = Modifier.fillMaxSize().objectFit(ObjectFit.Cover),
            src = Res.Drawable.CTA,
            description = "Call to Action foreground background image",
        )
        SimpleGrid(
            modifier = Modifier.fillMaxSize().align(alignment = Alignment.Center),
            numColumns = numColumns(base = 1, 2)

        ) {
            Column(
                modifier = Modifier
                    .margin(left= 40.px)
                    .padding(topBottom = 50.px)
                    .fillMaxWidth(80.percent)
                    .fillMaxHeight(),
                verticalArrangement = Arrangement.Center,
                horizontalAlignment = Alignment.Start
            ) {
                H1(
                    attrs = Modifier
                        .margin(0.px)
                        .fontSize(if (breakpoint > Breakpoint.MD) 50.px else 30.px)
                        .fontWeight(700)
                        .lineHeight(48.px)
                        .fontFamily(Res.Font.poppins_black)
                        .color(color = Color.white)
                        .toAttrs()

                ) {
                    Text(
                        value = "Excited To Use It First?",
                    )
                }
                P(
                    attrs = Modifier
                        .margin(0.px)
                        .fontSize(if (breakpoint > Breakpoint.MD) 20.px else 18.px)
                        .fontWeight(400)
                        .lineHeight(27.px)
                        .fontFamily(Res.Font.poppins_regular)
                        .color(color = Color.white)
                        .toAttrs()
                ) {
                    Text(
                        value = "Join our waitlist and become the first to use it \nClick the button and fill up the form",
                    )
                }

            }
            Column(
                modifier = Modifier
                    .margin(leftRight = 50.px)
                    .fillMaxWidth(45.percent)
                    .fillMaxHeight(),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Center
            ) {
                Button(
                    modifier = Modifier
                        .height(50.px)
                        .width(200.px)
                        .borderRadius(8.px)
                        .border(
                            width = 1.px,
                            color = Color("#FFFFFF"),
                            style = LineStyle.Solid
                        )
                        .background(
                            color = Color("#FFF")
                        )
                        .margin(topBottom = 16.px),
                    type = ButtonType.Submit,
                    onClick = {}
                ) {
                    Link(
                        path = "",
                        modifier = Modifier
                            .padding(16.px)
                            .color(Color("#39B54A"))
                            .fontSize(15.px)
                            .fontWeight(500)
                            .textAlign(TextAlign.Center)
                            .lineHeight(15.px)
                            .textDecorationLine(TextDecorationLine.None),
                        text = "Join Waitlist"
                    )
                }
            }
        }

    }
}