package tz.co.pesalock.components.howtouse

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.utils.Res


@Composable
fun HowToContent(
    breakpoint: Breakpoint,
    itemNumber: Int = 1,
    itemTitle: String = "Agree On What To Exchange",
    itemDescription: String = "Business/Seller meet on social media and agree on the type of Good tey want to sell to each other prior to everything. Recommend Pesalock as a mean to handle trust and delivery",
    iconLink: String = Res.Drawable.AGREE,
    iconSize: Int = 80,
    modifier: Modifier = Modifier.padding(10.px).margin(0.px),
    textAlign: TextAlign = if (breakpoint > Breakpoint.MD) TextAlign.Left else TextAlign.Center,
    hContentAlignment: Alignment.Horizontal = if (breakpoint > Breakpoint.MD) Alignment.Start else Alignment.CenterHorizontally,
) = Column(
    horizontalAlignment = hContentAlignment,
    verticalArrangement = Arrangement.SpaceEvenly,
    modifier = modifier,
) {
    Column(
        modifier = Modifier
            .padding(16.px)
    ) {
        Image(
            modifier = Modifier
                .border(
                    color = Color("#2F4858"),
                    width = 4.px,
                    style = LineStyle.Solid
                )
                .borderRadius(100.px)
                .padding(4.px),
            width = iconSize,
            height = iconSize,
            src = iconLink,
            description = "agreement representation",
        )
    }
    H1(
        attrs = Modifier
            .fontSize(16.px)
            .fontWeight(700)
            .fontFamily(Res.Font.poppins_bold)
            .padding(top = 8.px)
            .color(color = Color("#2F4858"))
            .lineHeight(15.px)
            .textAlign(textAlign = textAlign)
            .toAttrs()
    ) {
        Text(
            value = "$itemNumber. $itemTitle"
        )
    }

    P(
        attrs = Modifier
            .fontSize(16.px)
            .fontWeight(500)
            .fontFamily(Res.Font.poppins_regular)
            .padding(top = 4.px)
            .color(color = Color("#2F4858"))
            .lineHeight(30.px)
            .textAlign(textAlign = textAlign)
            .toAttrs()
    ) {
        Text(
            value = itemDescription
        )
    }

}
