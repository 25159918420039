package tz.co.pesalock.components.navigation

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.utils.Res


@Composable
fun LogoAndTitle(breakpoint: Breakpoint) {
    Row(
        modifier = Modifier
            .width(auto)
            .height(auto),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Image(
            width = if (breakpoint < Breakpoint.MD) 30 else 40,
            modifier = Modifier
                .margin(right = 8.px),
            src = Res.Drawable.PESALOCK_LOGO,
            description = "company logo",
        )
        H1(
            attrs = Modifier
                .fontSize(if (breakpoint < Breakpoint.MD) 20.px else 28.px)
                .color(Color("#2F4858"))
                .fontWeight(900)
                .toAttrs()
        ) {
            Text(value = "Pesa")
        }
        H1(
            attrs = Modifier
                .fontSize(if (breakpoint < Breakpoint.MD) 20.px else 28.px)
                .color(Color("#168C2A"))
                .fontWeight(900)
                .toAttrs()
        ) {
            Text(value = "Lock")
        }
    }
}
