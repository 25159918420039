package tz.co.pesalock.components.atoms

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.attrsModifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxHeight
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextArea
import tz.co.pesalock.utils.Res


@Composable
fun LabeledTextField(
    modifier: Modifier = Modifier,
    label: String,
    labelColor: CSSColorValue = Color("#FFFFFF"),
    inputId: String,
    textAreaValue: String? = null,
    numberOfLines: Int = 1,
    acceptedInput: InputType<String> = InputType.Text
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(space = 8.px, Alignment.CenterVertically)
    ) {
        Label(
            forId = inputId,
            attrs = Modifier
                .margin(0.px)
                .fillMaxWidth()
                .height(auto)
                .fontSize(16.px)
                .fontWeight(700)
                .color(color = labelColor)
                .textAlign(TextAlign.Start)
                .fontFamily(Res.Font.poppins_bold)
                .toAttrs()
        ) {
            Text(value = label)
        }
        if (numberOfLines > 1) {
            TextArea(
//                value = textAreaValue,
                attrs = Modifier
                    .margin(0.px)
                    .border(
                        width = 1.px,
                        color = Color("#233642"),
                        style = LineStyle.Solid
                    )
                    .borderRadius(8.px)
                    .color(
                        color = Color(labelColor.toString())
                    )
                    .fontFamily(Res.Font.poppins_medium)
                    .fontWeight(500)
                    .lineHeight(27.px)
                    .fillMaxWidth()
                    .minHeight(200.px)
                    .maxHeight(200.px)
                    .id(inputId)
                    .attrsModifier {
                        attr(attr = "placeholder", value = label)
                        attr(attr = "required", value = "true")
                        attr(attr = "name", value = inputId)
                    }
                    .padding(all = 16.px)
                    .toAttrs()
            )
        } else {
            Input(
                type = acceptedInput,
                attrs = Modifier
                    .margin(0.px)
                    .border(
                        width = 1.px,
                        color = Color("#233642"),
                        style = LineStyle.Solid
                    )
                    .fontFamily(Res.Font.poppins_medium)
                    .fontWeight(500)
                    .lineHeight(27.px)
                    .fillMaxWidth()
                    .borderRadius(8.px)
                    .color(
                        color = Color(labelColor.toString())
                    )
                    .fillMaxWidth()
                    .height(50.px)
                    .id(inputId)
                    .attrsModifier {
                        attr(attr = "placeholder", value = label)
                        attr(attr = "required", value = "true")
                        attr(attr = "name", value = inputId)
                    }
                    .padding(leftRight = 16.px)
                    .toAttrs()
            )

        }
    }
}