package tz.co.pesalock.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import tz.co.pesalock.components.herosection.BoxedFlex
import tz.co.pesalock.components.herosection.MainAction
import tz.co.pesalock.components.herosection.PesalockAppPhoto

@Composable
fun HeroSection(breakpoint: Breakpoint) {
    BoxedFlex(
        breakpoint
    ) {
        if (breakpoint > Breakpoint.MD) {
            MainAction(breakpoint)
            PesalockAppPhoto(breakpoint)
        } else {
            PesalockAppPhoto(breakpoint)
            MainAction(breakpoint)
        }
    }
}


