package tz.co.pesalock.components.navigation

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.icons.fa.FaBars
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import tz.co.pesalock.components.atoms.MenuItem
import tz.co.pesalock.utils.menuList


@Composable
fun MenuList(
    breakpoint: Breakpoint,
    onDrawerClicked: () -> Unit
    ) {
    Row(
        modifier = Modifier
            .thenIf(breakpoint > Breakpoint.MD, Modifier.fillMaxWidth(35.percent))
            .thenIf(breakpoint < Breakpoint.MD, Modifier.width(auto))
            .fillMaxHeight()
            .margin(
                right = if (breakpoint > Breakpoint.MD) 400.px else 0.px
            )
        ,
        horizontalArrangement = Arrangement.SpaceEvenly,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        if (breakpoint <= Breakpoint.LG) {
            FaBars(
                modifier = Modifier
                    .color(Color("#FFFFFF"))
                    .onClick {
                        onDrawerClicked()
                    },
                size = if(breakpoint <= Breakpoint.SM) IconSize.X2 else if(breakpoint <= Breakpoint.MD) IconSize.X3 else IconSize.X1,
            )
        } else {
            menuList.forEach { item ->
                MenuItem(
                    locationId = item.locationId,
                    itemName = item.itemName
                )
            }
        }
    }
}