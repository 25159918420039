package tz.co.pesalock.components.tools.address

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.utils.Res


@Composable
fun AddressItem(
    modifier: Modifier = Modifier,
    icon: @Composable () -> Unit,
    channel: String,
    details: String,
) {
    Column(
        modifier = Modifier.padding(leftRight = 16.px).height(auto),
        verticalArrangement = Arrangement.spacedBy(space = 2.px, alignment = Alignment.CenterVertically),
        horizontalAlignment = Alignment.Start
    ) {
        Row(
            modifier = Modifier.height(auto),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.spacedBy(space = 2.px, alignment = Alignment.CenterHorizontally)
        ) {
            icon()
            H1(
                attrs = Modifier
                    .margin(bottom = 8.px)
                    .fontSize(18.px)
                    .lineHeight(32.px)
                    .fontFamily(Res.Font.poppins_bold)
                    .fontWeight(700)
                    .color(color = Color("#2F4858"))
                    .toAttrs()
            ) {
                Text(
                    value = channel
                )
            }
        }

        P(
            attrs = Modifier
                .margin(0.px)
                .fontSize(18.px)
                .lineHeight(27.px)
                .fontFamily(Res.Font.poppins_regular)
                .fontWeight(400)
                .color(color = Color("#808080"))
                .toAttrs()
        ) {
            Text(
                value = details
            )
        }
    }
}