package tz.co.pesalock.components.herosection

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import tz.co.pesalock.utils.Res

@Composable
internal fun PesalockAppPhoto(breakpoint: Breakpoint) {
    Column(
        modifier = Modifier
            .fillMaxHeight()
            .fillMaxWidth(if(breakpoint < Breakpoint.MD) 100.percent else 50.percent)
            .thenIf(breakpoint < Breakpoint.MD, Modifier.background(color = Color("#FFF")))
        ,
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.End
    ) {
        if (breakpoint > Breakpoint.MD) {
            Image(
                src = Res.Drawable.APP_DESKTOP,
                description = "The pesalock landing page",
                modifier = Modifier
                    .width(442.px)
                    .height(886.px),
            )
        } else if (breakpoint <= Breakpoint.SM){
            Image(
                src = Res.Drawable.APP_MOBILE_SMALL,
                description = "The pesalock landing page",
                modifier = Modifier
                    .width(221.px)
                    .height(443.px),
            )
        }
        else {
            Image(
                src = Res.Drawable.APP_MOBILE,
                description = "The pesalock landing page",
                modifier = Modifier
                    .width(221.px)
                    .height(886.px),
            )
        }
    }
}