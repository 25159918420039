package tz.co.pesalock.components.tools.footer

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.utils.Res


@Composable
fun LogoAndCatch(
    modifier: Modifier = Modifier.fillMaxWidth().height(auto),
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.spacedBy(10.px, alignment = Alignment.CenterVertically )
    ) {
        FooterLogo()
        P(
            attrs = Modifier
                .margin(0.px)
                .height(auto)
                .fontSize(16.px)
                .lineHeight(27.px)
                .fontFamily(Res.Font.poppins_regular)
                .fontWeight(400)
                .color(Color("#FFF"))
                .toAttrs()
        ){
            Text(
                value = "Your trusted friend in online purchases and sales"
            )
        }
    }
}