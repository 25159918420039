package tz.co.pesalock.components.atoms

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.css.Transition
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.cursor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.onClick
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.overflow
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.textDecorationLine
import com.varabyte.kobweb.compose.ui.modifiers.transition
import com.varabyte.kobweb.compose.ui.modifiers.translateX
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.modifiers.zIndex
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.Position
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vh
import tz.co.pesalock.components.navigation.LogoAndTitle
import tz.co.pesalock.utils.Res
import tz.co.pesalock.utils.menuList

@Composable
fun Drawer(
    onDismiss: () -> Unit
) {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    var translateX by remember { mutableStateOf((-100).percent) }
    var opacity by remember { mutableStateOf(0.percent) }

    LaunchedEffect(breakpoint) {
        translateX = 0.percent
        opacity = 97.percent
        if (breakpoint > Breakpoint.MD) {
            scope.launch {
                delay(500)
                translateX = (-100).percent
                opacity = 0.percent
                delay(500)
            }
        }
    }
    Column(
        modifier = Modifier
            .background(color = Color("#2F4858"))
            .fillMaxWidth()
            .height(100.vh)
            .position(Position.Fixed)
            .zIndex(2)
            .opacity(opacity)
            .transition(Transition.of(property = "opacity", duration = 500.ms)),
        verticalArrangement = Arrangement.SpaceEvenly,
        horizontalAlignment = Alignment.Start,
    ) {

        Column(
            modifier = Modifier
                .background(color = Color.white)
                .width(if (breakpoint < Breakpoint.MD) 70.percent else 25.percent)
                .padding(topBottom = 50.px, leftRight = 20.px)
                .overflow(overflow = Overflow.Hidden)
                .scrollBehavior(ScrollBehavior.Smooth)
                .fillMaxHeight()
                .borderRadius(topRight = 20.px, bottomRight = 20.px)
                .translateX(tx = translateX)
                .transition(Transition.of(property = "translate", duration = 500.ms)),
            verticalArrangement = Arrangement.spacedBy(20.px, alignment = Alignment.Top),
            horizontalAlignment = Alignment.Start,
        ) {
            Row(
                modifier = Modifier.margin(bottom = 4.px),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                LogoAndTitle(breakpoint)
                FaXmark(
                    modifier = Modifier
                        .color(color = Color("#2F4858"))
                        .cursor(Cursor.Pointer)
                        .margin(left = 15.px)
                        .onClick {
                            scope.launch {
                                translateX = (-100).percent
                                opacity = 0.percent
                                delay(500)
                                onDismiss()
                            }
                        },
                    size = IconSize.LG
                )

            }

            menuList.forEach { item ->
                MenuItem(
                    locationId = item.locationId,
                    itemName = item.itemName,
                    modifier = Modifier
                        .fontSize(16.px)
                        .textDecorationLine(TextDecorationLine.None)
                        .color(color = Color("#2F4858"))
                        .fontWeight(value = 800)
                        .fontFamily(Res.Font.poppins_bold)
                        .thenIf(
                            breakpoint < Breakpoint.MD,
                            Modifier.margin(bottom = 10.px)
                        )
                        .onClick {
                            scope.launch {
                                translateX = (-100).percent
                                opacity = 0.percent
                                delay(500)
                                onDismiss()
                            }
                        }
                )
            }
        }
    }
}