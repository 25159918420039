package tz.co.pesalock.components.herosection

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px

@Composable
fun CoRFlex(
    startAtTop: Boolean = false,
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier
        .fillMaxWidth()
        .height(auto)
        .padding(topBottom = if (breakpoint > Breakpoint.MD) 16.px else 4.px),
    content: @Composable () -> Unit
) {
    if (breakpoint > Breakpoint.MD) {
        Row(
            modifier = modifier,
            horizontalArrangement = Arrangement.Start,
            verticalAlignment = if (startAtTop) Alignment.Top else Alignment.CenterVertically
        ) {
            content()
        }
    } else {
        Column(
            modifier = modifier,
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = if (startAtTop) Arrangement.Top else Arrangement.spacedBy(16.px, alignment = Alignment.CenterVertically)
        ) {
            content()
        }
    }
}