package tz.co.pesalock.tools

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px

fun Modifier.underline(breakpoint: Breakpoint) = fillMaxWidth(10.percent)
    .height(5.px)
    .background(color = Color("#39B54A"))
    .border(
        width = 1.px,
        color = Color("#39B54A")
    )
    .borderRadius(8.px)
    .margin(topBottom = if (breakpoint > Breakpoint.MD) 20.px else 10.px, leftRight = 12.px)

@Composable
fun Underline() {
    Row(modifier = Modifier.underline(breakpoint = rememberBreakpoint()), content = {})
}
