package tz.co.pesalock.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.css.ScrollSnapType
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.id
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.maxWidth
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.compose.ui.modifiers.scrollSnapType
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import tz.co.pesalock.components.atoms.BackToTop
import tz.co.pesalock.components.atoms.Drawer
import tz.co.pesalock.components.howtouse.HowToUseApp
import tz.co.pesalock.components.tools.CallToAction
import tz.co.pesalock.components.tools.Home
import tz.co.pesalock.components.tools.address.Contact
import tz.co.pesalock.components.tools.footer.Footer
import tz.co.pesalock.utils.Constants.SECTION_WIDTH

@Page
@Composable
fun HomePage() {
    Box(modifier = Modifier.fillMaxSize()) {
        //navigation drawer
        var drawerState by remember { mutableStateOf(false) }
        val breakpoint = rememberBreakpoint()
        Column(
            modifier = Modifier
                .fillMaxSize()
                .background(color = Color.white)
                .scrollSnapType(scrollSnapType = ScrollSnapType.Y)
                .scrollBehavior(scrollBehavior = ScrollBehavior.Smooth),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top
        ) {
            Home(
                onDrawerClicked = {
                    drawerState = true
                },
                breakpoint,
                modifier = Modifier
                    .maxWidth(SECTION_WIDTH.px)
                    .height(auto)
                    .background(color = Color.white)
                    .id("home")
            )
            HowToUseApp(
                breakpoint,
                modifier = Modifier
                    .padding(topBottom = 50.px)
                    .fillMaxWidth(90.percent)
                    .id("how-it-works")
            )
            CallToAction(breakpoint)
            Contact(
                breakpoint,
                modifier = Modifier
                    .padding()
                    .fillMaxWidth(90.percent)
                    .height(auto)
                    .id("contact")
//                    .border(width = 1.px, color = Color.red, style = LineStyle.Solid)
            )
            Footer(
                breakpoint = breakpoint,
                modifier = Modifier
                    .margin(0.px)
                    .fillMaxWidth()
                    .height(auto)
                    .background(color = Color("#2F4858"))
            )
        }

        BackToTop(breakpoint)
        if (drawerState) {
            Drawer(
                onDismiss = {
                    drawerState = false
                },
            )
        }
    }
}
