package tz.co.pesalock.components.tools.footer

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.vw
import tz.co.pesalock.utils.about
import tz.co.pesalock.utils.company
import tz.co.pesalock.utils.getCurrentYear
import tz.co.pesalock.utils.product
import tz.co.pesalock.utils.socials


@Composable
fun RightFooterPanel(
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier
        .thenIf(breakpoint > Breakpoint.MD, other = Modifier.padding(50.px))
        .thenIf(breakpoint < Breakpoint.MD, other = Modifier.padding(leftRight = 10.px, topBottom = 20.px))
        .width(auto)
        .fillMaxHeight()
) {
    Column(
        modifier = modifier,
        verticalArrangement = if (breakpoint > Breakpoint.MD) Arrangement.Top else Arrangement.spacedBy(15.px, alignment = Alignment.CenterVertically),
        horizontalAlignment = if (breakpoint > Breakpoint.MD) Alignment.Start else Alignment.CenterHorizontally
    ) {
        SimpleGrid(
            modifier = Modifier
                .thenIf(breakpoint > Breakpoint.MD, other = Modifier.width(80.percent))
                .thenIf(breakpoint < Breakpoint.MD, other = Modifier.width(auto)),
            numColumns = numColumns(base = 2, md = 4, lg = 4)
        ) {
            FooterList(
                modifier = Modifier
                    .width(auto)
                    .height(auto)
                    .thenIf(
                        breakpoint < Breakpoint.MD,
                        other = Modifier.margin(leftRight = 20.px, topBottom = 20.px)
                    ),
                dataList = socials,
                title = "Social Media",
                breakpoint = breakpoint
            )

            FooterList(
                breakpoint = breakpoint,
                modifier = Modifier
                    .width(auto)
                    .height(auto)
                    .thenIf(
                        breakpoint < Breakpoint.MD,
                        other = Modifier.margin(leftRight = 20.px, topBottom = 20.px)
                    ),
                dataList = product,
                title = "Product"
            )
            FooterList(
                modifier = Modifier
                    .width(auto)
                    .height(auto)
                    .thenIf(
                        breakpoint < Breakpoint.MD,
                        other = Modifier.margin(leftRight = 20.px, topBottom = 20.px)
                    ),
                dataList = about,
                title = "About",
                breakpoint = breakpoint
            )
            FooterList(
                modifier = Modifier
                    .width(auto)
                    .height(auto)
                    .thenIf(
                        breakpoint < Breakpoint.MD,
                        other = Modifier.margin(leftRight = 20.px, topBottom = 20.px)
                    ),
                dataList = company,
                title = "Company",
                breakpoint = breakpoint
            )

        }

        if (breakpoint <= Breakpoint.MD) {
            CopyrightMessage(getCurrentYear())
        }
    }
}