package tz.co.pesalock.components.herosection

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.opacity
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text

@Composable
internal fun MainAction(
    breakpoint: Breakpoint
) {
    Column(
        modifier = Modifier
            .fillMaxWidth(if (breakpoint < Breakpoint.MD) 100.percent else 40.percent)
            .fillMaxHeight(if (breakpoint > Breakpoint.MD) 100.percent else 80.percent)
            .margin(top = if (breakpoint > Breakpoint.MD) 200.px else 90.px),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = if (breakpoint > Breakpoint.MD) Alignment.Start else Alignment.CenterHorizontally
    ) {
        Column(
            modifier = Modifier
                .thenIf(
                    breakpoint < Breakpoint.MD,
                    Modifier
                        .background(color = Color("#FFF"))
                        .opacity(90.percent)
                )
                .border(
                    width = if (breakpoint < Breakpoint.MD) 0.px else 0.px,
                    color = Color("#2F4858"),
                    style = LineStyle.Solid
                )
                .borderRadius(if (breakpoint < Breakpoint.MD) 8.px else 0.px)
                .padding(all = if (breakpoint < Breakpoint.MD) 16.px else 0.px)
                .fillMaxSize(if (breakpoint > Breakpoint.MD) 100.percent else 80.percent),
            horizontalAlignment = if (breakpoint > Breakpoint.MD) Alignment.Start else Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.SpaceEvenly
        ) {
            P(
                attrs = Modifier
                    .margin(0.px)
                    .color(Color("#2F4858"))
                    .fontSize(if (breakpoint > Breakpoint.MD) 40.px else 30.px)
                    .fontWeight(900)
                    .textAlign(TextAlign.Start)
                    .toAttrs()
            ) {
                Text(
                    value = "SELL AND BUY"
                )
            }

            Row(
                modifier = Modifier,
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.spacedBy(4.px, alignment = Alignment.Start)
            ) {
                P(
                    attrs = Modifier
                        .margin(0.px)
                        .color(Color("#2F4858"))
                        .fontWeight(900)
                        .textAlign(TextAlign.Start)
                        .fontSize(if (breakpoint > Breakpoint.MD) 40.px else 30.px)
                        .toAttrs()
                ) {
                    Text(
                        value = "ONLINE ",
                    )
                }
                P(
                    attrs = Modifier
                        .margin(0.px)
                        .padding(topBottom = 8.px)
                        .color(Color("#39B54A"))
                        .fontSize(if (breakpoint > Breakpoint.MD) 40.px else 30.px)
                        .fontWeight(900)
                        .textAlign(TextAlign.Start)
                        .toAttrs()
                ) {
                    Text(value = "SECURELY")
                }
            }
            P(
                attrs = Modifier
                    .margin(0.px)
                    .padding(topBottom = 8.px)
                    .color(Color("#2F4858"))
                    .fontSize(15.px)
                    .fontWeight(600)
                    .textAlign(if (breakpoint > Breakpoint.MD) TextAlign.Start else TextAlign.Center)
                    .lineHeight(30.px)
                    .toAttrs()
            ) {
                Text(
                    value = "Say goodbye to uncertainties and embrace a new era of trust and convenience. Join us now to experience the future of secure online commerce!",
                )
            }

        }


        CoRFlex(breakpoint = breakpoint) {
            Button(
                onClick = { },
                modifier = Modifier
                    .margin(left = if (breakpoint < Breakpoint.MD) 0.px else 16.px)
                    .height(50.px)
                    .thenIf(breakpoint > Breakpoint.MD, Modifier.width(200.px))
                    .thenIf(breakpoint < Breakpoint.MD, Modifier.fillMaxWidth(80.percent))
                    .borderRadius(8.px)
                    .border(width = 1.px, color = Color("#39B54A"))
                    .background(color = Color("#39B54A")),
                enabled = true
            ) {
                Link(
                    modifier = Modifier
                        .padding(16.px)
                        .color(Color("#FFF"))
                        .fontSize(15.px)
                        .fontWeight(500)
                        .textAlign(TextAlign.Center)
                        .lineHeight(15.px),
                    path = "",
                    text = "Join Waitlist",
                    openExternalLinksStrategy = OpenLinkStrategy.IN_NEW_TAB
                )
            }
            Button(
                onClick = { },
                modifier = Modifier
                    .margin(left = if (breakpoint < Breakpoint.MD) 0.px else 16.px)
                    .height(50.px)
                    .thenIf(breakpoint > Breakpoint.MD, Modifier.width(200.px))
                    .thenIf(breakpoint < Breakpoint.MD, Modifier.fillMaxWidth(80.percent))
                    .border(width = .5.px, color = Color("#2F4858"), style = LineStyle.Solid)
                    .borderRadius(8.px)
                    .background(color = Color("#FFFFFF"))

            ) {
                Link(
                    path = "",
                    modifier = Modifier
                        .color(color = Color("#2F4858"))
                        .fontSize(15.px)
                        .fontWeight(500)
                        .textAlign(TextAlign.Center)
                        .lineHeight(15.px),
                    text = "Learn More"
                )

            }
        }
    }
}