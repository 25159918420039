package tz.co.pesalock.components.tools.footer

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.utils.Res


@Composable
fun FooterLogo() {
    Row(
        modifier = Modifier.fillMaxWidth().height(auto),
        horizontalArrangement = Arrangement.spacedBy(space = 8.px, alignment = Alignment.Start),
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Image(
            modifier = Modifier
                .width(40.px)
                .padding(right = 8.px),
            src = Res.Drawable.LOGO,
            description = "logo",
        )
        H1(
            attrs = Modifier
                .margin(0.px)
                .fontSize(24.px)
                .lineHeight(32.px)
                .fontFamily(Res.Font.poppins_bold)
                .fontWeight(600)
                .color(Color("#fff"))
                .toAttrs()
        ) {
            Text(
                value = "PesaLock"
            )
        }
    }
}