package tz.co.pesalock.components.tools.address

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.utils.Address
import tz.co.pesalock.utils.Res


@Composable
fun OfficeAddress(
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier,
    addressChannels: List<Address>
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Top

    ) {
        P(
            attrs = Modifier
                .margin(0.px)
                .width(auto)
                .height(auto)
                .fontSize(20.px)
                .fontFamily(Res.Font.poppins_regular)
                .lineHeight(27.px)
                .fontWeight(400)
                .color(color = Color("#808080"))
                .toAttrs()
        ) {
            Text(
                value = "You can reach us through our various",
            )
        }
        P(
            attrs = Modifier
                .margin(0.px)
                .width(auto)
                .height(auto)
                .fontSize(20.px)
                .fontFamily(Res.Font.poppins_regular)
                .lineHeight(27.px)
                .fontWeight(400)
                .color(color = Color("#808080"))
                .toAttrs()
        ) {
            Text(
                value = "channels as shown below",
            )
        }

        Column(
            modifier = Modifier.margin(top = 20.px),
            horizontalAlignment = Alignment.Start,
            verticalArrangement = Arrangement.spacedBy(20.px, alignment = Alignment.CenterVertically)
        ) {
        addressChannels.forEach { addressChannel ->
                AddressItem(
                    channel = addressChannel.channel,
                    icon = addressChannel.icon,
                    details = addressChannel.details,
                )
            }

        }
    }
}