package tz.co.pesalock.components.atoms

import androidx.compose.runtime.Composable
import androidx.compose.web.events.SyntheticMouseEvent
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.background
import com.varabyte.kobweb.compose.ui.modifiers.border
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.forms.Button
import org.jetbrains.compose.web.attributes.ButtonType
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text


@Composable
fun ActionButton(
    containerColor: String= "#FFFFFF",
    text: String,
    textColor: String = "#39B54A",
    onClick: (SyntheticMouseEvent) -> Unit,
    modifier: Modifier
) {
    Button(
        type = ButtonType.Submit,
        onClick = onClick,
        modifier = modifier,
        enabled = true
    ) {
        P(
            attrs = Modifier
                .fontSize(15.px)
                .fontWeight(500)
                .textAlign(TextAlign.Center)
                .lineHeight(15.px)
                .color(Color(textColor))
                .toAttrs()
        ) {
            Text(
                value = text
            )
        }
    }
}