package tz.co.pesalock.components.tools.footer

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.percent


@Composable
fun Footer(
    modifier: Modifier = Modifier,
    breakpoint: Breakpoint
) = Row(
    modifier = modifier,
    horizontalArrangement = Arrangement.SpaceEvenly,
    verticalAlignment = Alignment.Top
) {
    SimpleGrid(
        modifier = Modifier.fillMaxWidth(100.percent),
        numColumns = numColumns(base = 1, md = 2, lg = 2)
    ){
        LeftFooterPanel(breakpoint)
        RightFooterPanel(breakpoint)
    }

}
