package tz.co.pesalock.components.tools.footer

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.utils.Res


@Composable
fun FooterList(
    modifier: Modifier = Modifier.height(auto),
    dataList: List<String>,
    title: String,
    breakpoint: Breakpoint,
) = Column(
    modifier = modifier,
    horizontalAlignment = Alignment.Start,
    verticalArrangement = Arrangement.spacedBy(8.px, Alignment.Top)
) {
    H2(
        attrs = Modifier
            .margin(0.px)
            .height(auto)
            .fontSize(if (breakpoint > Breakpoint.MD) 16.px else 20.px)
            .fontFamily(Res.Font.poppins_bold)
            .fontWeight(700)
            .lineHeight(32.px)
            .color(Color("#FFF"))
            .toAttrs()
    ) {
        Text(
            value = title
        )
    }

    dataList.forEach { data ->
        P(
            attrs = Modifier
                .margin(0.px)
                .height(auto)
                .fontSize(if (breakpoint > Breakpoint.MD) 16.px else 18.px)
                .lineHeight(16.px)
                .fontFamily(Res.Font.poppins_regular)
                .fontWeight(400)
                .color(Color("#FFF"))
                .toAttrs()
        ) {
            Text(
                value = data
            )
        }

    }
}