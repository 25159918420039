package tz.co.pesalock.components.tools.footer

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.P
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.utils.Res
import tz.co.pesalock.utils.getCurrentYear
import kotlin.js.Date


@Composable
fun LeftFooterPanel(
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier
        .thenIf(breakpoint>Breakpoint.MD, other = Modifier.width(auto))
        .thenIf(breakpoint<Breakpoint.MD, other = Modifier.fillMaxWidth())
        .fillMaxHeight()
        .padding(50.px)
) {

    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.SpaceBetween
    ) {
        LogoAndCatch()
        if (breakpoint > Breakpoint.MD) {
            CopyrightMessage(getCurrentYear())
        }
    }
}

@Composable
internal fun CopyrightMessage(year: Int) {
    P(
        attrs = Modifier
            .margin(0.px)
            .fontSize(16.px)
            .lineHeight(27.px)
            .fontFamily(Res.Font.poppins_regular)
            .fontWeight(400)
            .color(Color("#FFF"))
            .toAttrs()
    ) {
        Text(
            value = "Copyright \u00a9 $year, Pesalock Africa Limited "
        )
    }
}