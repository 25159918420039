package tz.co.pesalock.components.tools.address

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.lineHeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.Color
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text
import tz.co.pesalock.components.herosection.CoRFlex
import tz.co.pesalock.components.tools.MailForm
import tz.co.pesalock.tools.Underline
import tz.co.pesalock.utils.Res
import tz.co.pesalock.utils.addressChannels


@Composable
fun Contact(
    breakpoint: Breakpoint,
    modifier: Modifier
) {
    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top
    ) {
        H1(
            attrs = Modifier
                .margin(0.px)
                .fontSize(if (breakpoint< Breakpoint.MD) 24.px else 48.px)
                .lineHeight(63.98.px)
                .fontFamily(Res.Font.poppins_bold)
                .fontWeight(700)
                .color(color = Color("#2F4858"))
                .textAlign(TextAlign.Center)
                .toAttrs()
        ) {
            Text(
                value = "CONTACT US"
            )
        }

        Underline()

        CoRFlex(
            breakpoint = breakpoint,
            startAtTop = breakpoint > Breakpoint.MD,
            modifier = Modifier
                .margin(bottom = 30.px)
                .fillMaxWidth()
                .height(auto)
                .padding(topBottom = if (breakpoint > Breakpoint.MD) 16.px else 4.px),
        ) {
            MailForm(
                breakpoint = breakpoint,
                modifier = Modifier
                    .margin(topBottom = if (breakpoint > Breakpoint.MD) 0.px else 10.px, leftRight = if (breakpoint > Breakpoint.MD) 20.px else 5.px)
                    .fillMaxWidth(if (breakpoint > Breakpoint.MD) 50.percent else 100.percent)
                    .height(auto),
                onMessageSend = {}
            )
            OfficeAddress(
                breakpoint,
                addressChannels = addressChannels,
                modifier = Modifier
//                    .border(width = 1.px, color = Color.red, style = LineStyle.Solid)
                    .height(auto)
                    .margin(topBottom = if (breakpoint > Breakpoint.MD) 30.px else 10.px),
            )
        }
    }
}