package tz.co.pesalock.components.herosection

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.RowScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.silk.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.percent

@Composable
fun BoxedFlex(
    breakpoint: Breakpoint,
    modifier: Modifier = Modifier
        .fillMaxWidth()
        .fillMaxHeight(40.percent),
    content: @Composable () -> Unit
) {
    if (breakpoint > Breakpoint.MD) {
        Row(
            modifier = modifier,
            horizontalArrangement = Arrangement.SpaceEvenly,
            verticalAlignment = Alignment.Top
        ) {
            content()
        }
    } else {
        Box(
            modifier = modifier
        ) {
            content()
        }
    }
}